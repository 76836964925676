/* src/styles/main.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    font-family: "Open Sans", system-ui, sans-serif;
}

/* Add custom styles for the BuyerRights component */
.bg-gray-900 {
    background-color: #1a1a1a;
}

.text-yellow-400 {
    color: #f3a847;
}

.bg-gray-800 {
    background-color: #2e2e2e;
}

html,
body,
#root,
.ContactPage {
    height: 100%;
    margin: 0;
}

.ContactPage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.ContactPage main {
    flex: 1;
    /* This ensures the main content takes up available space */
}

@keyframes flash {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}

.animate-flash {
    animation: flash 2s infinite;
}

.image-slider {
    user-select: none;
    cursor: grab;
}
